@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Young+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300&display=swap');

@layer base{
  body{
    @apply font-[Raleway];
  }

  .coolFont{
    font-family: 'Young Serif', serif;;
  }
  li{
    @apply font-[Manrope];
  }

  .custom-form {
    max-width: 400px; 
    margin: 0 auto; 
  }
  
  .form-field {
    margin-bottom: 20px;
  }

  .form-input {
    background-color: lightgray;
    color: black;
    border-radius: 3px;
    height: 35px;
    padding-left: 10px;
    width: 85%;
    display: flex;
  }

  .message-input {
    background-color: lightgray;
    color: black;
    border-radius: 3px;
    height: 180px;
    padding-left: 10px;
    width: 85%;
  }

  .form-input::placeholder {
    color: black;
  }

  .message-input::placeholder {
    color: black;
  }
}

